import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Rails Developers Meetup 2018 Day 3 Extreme で弊社の宍戸が発表させていただきました",
  "date": "2018-07-19T03:05:06.000Z",
  "slug": "entry/2018/07/19/120506",
  "tags": ["medley"],
  "hero": "./2018_07_19.png",
  "heroAlt": "Rails developers meetup 2018"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部の平木です。`}</p>
    <p>{`去る 7/14(土)に`}<a parentName="p" {...{
        "href": "https://techplay.jp/event/679666"
      }}>{`Rails Developers Meetup 2018 Day 3 Extreme`}</a>{`というイベントが開催されまして、`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/63206"
      }}>{`弊社の宍戸`}</a>{`が`}<strong parentName="p">{`電子カルテとセキュリティガイドラインと AWS と私`}</strong>{`というタイトルで発表させていただきましたので、レポートさせていただきます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180719/20180719114338.jpg",
      "alt": "20180719114338.jpg"
    }}></img>
    <h1>{`発表のきっかけ`}</h1>
    <p>{`昨年に引き続き`}<a parentName="p" {...{
        "href": "https://rubykaigi.org/2018"
      }}>{`RubyKaigi 2018`}</a>{`で弊社 CTO の平山が`}<a parentName="p" {...{
        "href": "/entry/2018/06/06/151300"
      }}>{`LT スポンサーとして発表させていただいたり、ブースに出展をしていた`}</a>{`ことがきっかけになり、Rails Developers Meetup の主催者である平野さん（`}<a parentName="p" {...{
        "href": "https://twitter.com/yoshi_hirano"
      }}>{`@yoshi_hirano`}</a>{`)から平山へ出演のお声がけをしていただきました。イベントからイベントへ関係がつながるのはとても嬉しいですね。`}</p>
    <h1>{`発表テーマについて`}</h1>
    <p>{`せっかく、お声がけしていただいたので、発表テーマも弊社の特色が出るものが良いであろうと決まったのが今回のテーマです。`}</p>
    <p>{`4 月に発表した電子カルテシステム`}<strong parentName="p">{`CLINICS カルテ`}</strong>{`ですが、何度かこちらのブログでもエントリが上がっていますとおり、電子カルテというシステムを構築する上で、セキュリティはかなり重要なウェイトを占める要素になっています。`}</p>
    <p>{`このセキュリティを担保するための指針として`}<em parentName="p">{`3 省 4 ガイドライン`}</em>{`という総務省・経産省・厚労省の各省庁から出ている 4 種類のガイドラインが出ています。`}</p>
    <p>{`これを実際に AWS をベースとしたシステムを構築する際の一例として CLINICS カルテでの構築例を発表しようということになりました。`}</p>
    <h1>{`発表スライド`}</h1>
    <p>{`当日の発表スライドはこちらになります。`}</p>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/8a3ebe6ac58e48efa9e0494f2372131e" title="電子カルテとセキュリティガイドラインと AWS と私 /Rails Developers Meetup 2018 Day 3 Extreme" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "420px"
    }} data-ratio="1.3333333333333333"></iframe>
    <blockquote className="twitter-tweet"><p lang="ja" dir="ltr">電カルでガイドラインに沿うよう cloud trail とか vpc flow logs といった細かな AWS のサービス使ってるのか。<a href="https://twitter.com/hashtag/rdm2018A?src=hash&amp;ref_src=twsrc%5Etfw">#rdm2018A</a> <a href="https://twitter.com/hashtag/railsdm?src=hash&amp;ref_src=twsrc%5Etfw">#railsdm</a></p>&mdash; yuyasat (@yuyasat) <a href="https://twitter.com/yuyasat/status/1018036135581270017?ref_src=twsrc%5Etfw">July 14, 2018</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <p>{`会場ではスライドの色見がすっごく色褪せしていたりしましたが、発表した中での AWS のサービスは会場のみなさんの中でも、あまり馴染みがないものが多いようでスライドの写真など撮られている方もいらっしゃって、知見共有という意味で参考になった模様です。`}</p>
    <blockquote className="twitter-tweet"><p lang="ja" dir="ltr">クライアント認証の話たのしいしめっちゃ知見だな。ALB, API Gateway は TLS クライアント認証に非対応だったので、Nginx で認証させてるとのこと。<a href="https://twitter.com/hashtag/rdm2018A?src=hash&amp;ref_src=twsrc%5Etfw">#rdm2018A</a> <a href="https://twitter.com/hashtag/railsdm?src=hash&amp;ref_src=twsrc%5Etfw">#railsdm</a></p>&mdash; かつひささん (@katsuhisa__) <a href="https://twitter.com/katsuhisa__/status/1018037952553730049?ref_src=twsrc%5Etfw">July 14, 2018</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <blockquote className="twitter-tweet"><p lang="ja" dir="ltr">メドレーさんの発表、すげーよかった。<br />派手さはなくとも、こうやって一個ずつ地道に技術課題を解決している話はめちゃくちゃおもしろい。<a href="https://twitter.com/hashtag/rdm2018A?src=hash&amp;ref_src=twsrc%5Etfw">#rdm2018A</a> <a href="https://twitter.com/hashtag/railsdm?src=hash&amp;ref_src=twsrc%5Etfw">#railsdm</a></p>&mdash; かつひささん (@katsuhisa__) <a href="https://twitter.com/katsuhisa__/status/1018038937477005312?ref_src=twsrc%5Etfw">July 14, 2018</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <p>{`やはりクライアント認証に関しては普段サービス開発ではそこまでは使われないので、興味を持っていただいたようで良かったです!`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`弊社のような医療業界ではなくても、フルマネージドサービスでセキュリティを意識するような場面があればご参考になるかもしれないテーマをエンジニアの宍戸から発表させていただきました。`}</p>
    <p>{`社内で貯まった知見で機会があれば、ぜひ公開していきたいと思います。`}</p>
    <p>{`メドレーについて気になった方は、こちらからどうぞ。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley"
      }}>{`https://www.wantedly.com/companies/medley`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      